<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="订单号" prop="outTradeNo">
                <a-input v-model="queryParam.outTradeNo" placeholder="请输入订单号" allow-clear />
              </a-form-item>
            </a-col>
            <template>
              <a-col :md="8" :sm="24">
                <a-form-item label="交易状态" prop="tradeState">
                  <a-select placeholder="请选择交易状态" v-model="queryParam.tradeState">
                    <a-select-option v-for="(item, index) in tradeStateOptions" :key="index" :value="item.dictValue">
                      {{ item.dictLabel }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:recyclable:transRecordPurchase:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:transRecordPurchase:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:transRecordPurchase:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:recyclable:transRecordPurchase:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:transRecordPurchase:edit']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:recyclable:transRecordPurchase:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:recyclable:transRecordPurchase:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:recyclable:transRecordPurchase:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listTransRecordPurchase,
  delTransRecordPurchase,
  exportTransRecordPurchase
} from '@/api/recyclable/transRecordPurchase'
import CreateForm from './modules/CreateForm'

export default {
  name: 'TransRecordPurchase',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        outTradeNo: null,
        userId: null,
        tradeState: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '订单号',
          dataIndex: 'outTradeNo',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '商品描述',
        //   dataIndex: 'description',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '商品描述',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '总金额(元)',
          dataIndex: 'totalAmount',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.totalAmount ? record.totalAmount / 100 : 0
          }
        },
        {
          title: '交易状态', // ,SUCCESS：支付成功 NOTPAY：未支付 PAYERROR：支付失败(其他原因，如银行返回失败) PENDING：待付款
          dataIndex: 'tradeState',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.optionsFormat(this.tradeStateOptions, record.tradeState)
          }
        },
        {
          title: '微信系统产的订单号',
          dataIndex: 'transactionId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
          align: 'center'
        }
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // }
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ],
      tradeStateOptions: [
        { dictLabel: '支付成功', dictValue: 'SUCCESS' },
        { dictLabel: '未支付', dictValue: 'NOTPAY' },
        { dictLabel: '支付失败', dictValue: 'PAYERROR' },
        { dictLabel: '待付款', dictValue: 'PENDING' }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询购买记录列表 */
    getList() {
      this.loading = true
      listTransRecordPurchase(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        outTradeNo: undefined,
        userId: undefined,
        tradeState: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delTransRecordPurchase(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportTransRecordPurchase(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
